<template>
    <div class="k-flex-col">
        <template v-if="!done">
            <b-form-group>
                <label> <b>Adresse e-mail</b> </label>
                <b-form-input
                    v-model="mail"
                    :state="isMailValid"
                    placeholder="Adresse e-mail"
                    autocomplete="nope"
                    trim
                />
                <b-form-invalid-feedback :state="isMailValid">
                    Veuillez saisir une adresse e-mail valide
                </b-form-invalid-feedback>
            </b-form-group>

            <div class="k-flex-row">
                <b-button class="k-flex-center" variant="secondary" to="Login">
                    <b-icon-arrow-left-circle class="mr-2" />Retour
                </b-button>

                <b-button
                    class="k-flex-center"
                    variant="kalkin-2"
                    @click="resetPassword()"
                    :disabled="!isMailValid"
                >
                    Réinitialiser votre mot de passe
                </b-button>
            </div>
        </template>
        <template v-else>
            <div class="text-kalkin-2 text-center mb-3">
                Un e-mail permettant de réinitialiser votre mot de passe vous a
                été envoyé.
            </div>
            <b-button class="k-flex-center" variant="secondary" to="Login">
                <b-icon-arrow-left-circle class="mr-2" />Retour
            </b-button>
        </template>
    </div>
</template>

<script>
import validator from "@/tools/validator";

export default {
    data: function() {
        return {
            mail: null,
            done: false,
        };
    },

    computed: {
        isMailValid() {
            if (this.mail === null) {
                return null;
            } else {
                return validator.validateEmail(this.mail);
            }
        },
    },

    methods: {
        async resetPassword() {
            await this.$store.dispatch("user/resetPassword", {
                mail: this.mail,
            });
            this.done = true;
        },
    },
};
</script>
