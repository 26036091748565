<template>
    <div class="k-flex-col w-100">
        <div class="text-center mb-2">
            <h1>MOT DE PASSE OUBLIÉ</h1>
        </div>
        <div class="k-form-fp">
            <FormResetPassword />
        </div>
    </div>
</template>

<script>
import FormResetPassword from "@/components/misc/form/FormResetPassword.vue";
export default {
    name: "ResetPassword",
    components: {
        FormResetPassword,
    },
};
</script>
